import commonUseCase from './common-usecase.yml'
import formsWithConditions from './with-conditions.yml'
import formsWithDynamicValues from './with-dynamic-values.yml'
import formsWithSharedVariables from './with-shared-variables.yml'
import formsWithUseCases from './with-usecases.yml'

// Base64 encode to ensure multiline strings are preserved
export const formExamples = {
  commonUseCase: btoa(commonUseCase),
  formsWithConditions: btoa(formsWithConditions),
  formsWithUseCases: btoa(formsWithUseCases),
  formsWithDynamicValues: btoa(formsWithDynamicValues),
  formsWithSharedVariables: btoa(formsWithSharedVariables),
}
